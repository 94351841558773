import { type z } from 'zod'
import { PUBLIC_PREFIX, isBrowser } from './constants'

export function createEnvSystem<T extends z.ZodObject<any, any>>(schema: T) {
  type EnvSchema = z.infer<T>
  type EnvKeys = keyof EnvSchema & string

  const publicKeys = Object.keys(schema.shape).filter((k) => k.startsWith(PUBLIC_PREFIX))

  // Parse the default values from schema
  function parseWithDefaults(value: unknown, key: EnvKeys) {
    const fieldSchema = schema.shape[key]
    try {
      return fieldSchema.parse(value)
    } catch {
      // If parsing fails but the schema has a default, use that
      if ('default' in fieldSchema && typeof fieldSchema.default === 'function') {
        return fieldSchema.default()
      }
      throwMissingEnvLog(key)
      return undefined
    }
  }

  function getEnv(): EnvSchema
  function getEnv<K extends EnvKeys>(key: K): EnvSchema[K]
  function getEnv(key?: EnvKeys): any {
    if (!key) {
      return isBrowser() ? (window.__ENV as EnvSchema) : (process.env as unknown as EnvSchema)
    }

    if (isBrowser()) {
      if (!key.toString().startsWith(PUBLIC_PREFIX)) {
        console.error(
          `[ENV] Attempting to access non-public environment variable "${key}" in browser. ` +
            `Only NEXT_PUBLIC_ variables are accessible in the browser.\n` +
            `Available public variables:\n${publicKeys.map((k) => `  - ${k}`).join('\n')}`,
        )
        return undefined
      }

      return parseWithDefaults(window.__ENV[key], key)
    }

    return parseWithDefaults(process.env[key], key)
  }

  return { getEnv }
}

function throwMissingEnvLog(key: string) {
  console.error(
    `Missing environment variable: "${key}". This should never happen as all envs are validated at startup. ` +
      'This is likely a bug in the application code.',
  )
}
