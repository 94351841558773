import { z } from 'zod'

// Custom parsers
const asUrlString = z.string().url()
const asIntPositive = z.coerce.number().positive().int()
const asIntNonNegative = z.coerce.number().nonnegative().int()
const asBool = z.enum(['true', 'false']).transform((val) => val === 'true')

// Environment enum
const APP_ENV = ['dev', 'stage', 'preprod', 'prod'] as const
type AppEnv = (typeof APP_ENV)[number]

export const envsSchema = z.object({
  // Algolia
  NEXT_PUBLIC_ALGOLIA_APP_ID: z.string().min(1),
  NEXT_PUBLIC_ALGOLIA_SEARCH_KEY: z.string().min(1),
  NEXT_PUBLIC_ALGOLIA_INDEX_PREFIX: z.string().min(1),

  // Data Provider
  DATA_PROVIDER_URL: asUrlString,
  DATA_PROVIDER_HEALTH_URL: asUrlString,

  // Contentful Access Tokens
  CONTENTFUL_ACCESS_TOKEN_GLOBAL: z.string().min(1),
  CONTENTFUL_ACCESS_TOKEN_AT: z.string().min(1),
  CONTENTFUL_ACCESS_TOKEN_BE: z.string().min(1),
  CONTENTFUL_ACCESS_TOKEN_BG: z.string().min(1),
  CONTENTFUL_ACCESS_TOKEN_CY: z.string().min(1),
  CONTENTFUL_ACCESS_TOKEN_CZ: z.string().min(1),
  CONTENTFUL_ACCESS_TOKEN_DE: z.string().min(1),
  CONTENTFUL_ACCESS_TOKEN_DK: z.string().min(1),
  CONTENTFUL_ACCESS_TOKEN_DZ: z.string().min(1),
  CONTENTFUL_ACCESS_TOKEN_EE: z.string().min(1),
  CONTENTFUL_ACCESS_TOKEN_EG: z.string().min(1),
  CONTENTFUL_ACCESS_TOKEN_ES: z.string().min(1),
  CONTENTFUL_ACCESS_TOKEN_FI: z.string().min(1),
  CONTENTFUL_ACCESS_TOKEN_FR: z.string().min(1),
  CONTENTFUL_ACCESS_TOKEN_GB: z.string().min(1),
  CONTENTFUL_ACCESS_TOKEN_GR: z.string().min(1),
  CONTENTFUL_ACCESS_TOKEN_HR: z.string().min(1),
  CONTENTFUL_ACCESS_TOKEN_HU: z.string().min(1),
  CONTENTFUL_ACCESS_TOKEN_IL: z.string().min(1),
  CONTENTFUL_ACCESS_TOKEN_IT: z.string().min(1),
  CONTENTFUL_ACCESS_TOKEN_LT: z.string().min(1),
  CONTENTFUL_ACCESS_TOKEN_LV: z.string().min(1),
  CONTENTFUL_ACCESS_TOKEN_MY: z.string().min(1),
  CONTENTFUL_ACCESS_TOKEN_NL: z.string().min(1),
  CONTENTFUL_ACCESS_TOKEN_NO: z.string().min(1),
  CONTENTFUL_ACCESS_TOKEN_PH: z.string().min(1),
  CONTENTFUL_ACCESS_TOKEN_PT: z.string().min(1),
  CONTENTFUL_ACCESS_TOKEN_RO: z.string().min(1),
  CONTENTFUL_ACCESS_TOKEN_RS: z.string().min(1),
  CONTENTFUL_ACCESS_TOKEN_SE: z.string().min(1),
  CONTENTFUL_ACCESS_TOKEN_SI: z.string().min(1),
  CONTENTFUL_ACCESS_TOKEN_SK: z.string().min(1),
  CONTENTFUL_ACCESS_TOKEN_TN: z.string().min(1),
  CONTENTFUL_ACCESS_TOKEN_TR: z.string().min(1),
  CONTENTFUL_ACCESS_TOKEN_TZ: z.string().min(1),
  CONTENTFUL_ACCESS_TOKEN_VN: z.string().min(1),
  CONTENTFUL_ACCESS_TOKEN_WW: z.string().min(1),
  CONTENTFUL_ENVIRONMENT: z.string().min(1),

  // Download Center
  NEXT_PUBLIC_DOWNLOAD_CENTER_SERVICE_URL: asUrlString,
  DOWNLOAD_CENTER_SERVICE_HEALTH_URL: asUrlString,
  NEXT_PUBLIC_DOP_URL: asUrlString,

  // One Trust
  NEXT_PUBLIC_ONE_TRUST_ID: z.string().min(1),

  NEXT_PUBLIC_HOST_BASE_URL: asUrlString,

  // App Environment
  NEXT_PUBLIC_APP_ENV: z.enum(APP_ENV),

  // Host
  HOSTNAME: z.string().default('localhost'),

  // App Config
  APPCONFIG_CONNECTION_STRING: z.string().min(1),

  // Next Auth
  NEXTAUTH_URL: asUrlString,
  NEXTAUTH_SECRET: z.string().min(1),

  // Okta
  OKTA_CLIENT_ID: z.string().min(1),
  OKTA_CLIENT_SECRET: z.string().min(1),
  OKTA_DOMAIN: asUrlString,

  // Global Account
  GLOBAL_ACCOUNT_API_URL: asUrlString,

  // DataDog
  NEXT_PUBLIC_DATADOG_LOGS_CLIENT_TOKEN: z.string().min(1),
  NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID: z.string().min(1),
  NEXT_PUBLIC_DATADOG_IS_RUM_ENABLED: asBool.default('false'),
  NEXT_PUBLIC_DATADOG_RUM_SAMPLE_RATE: asIntNonNegative.max(100).default(100),

  // Shared Auth and UI
  NEXT_PUBLIC_BASE_URL_SUFFIX: z.string().min(1),

  NEXT_PUBLIC_BROKEN_IMAGE_ICON_URL: asUrlString,
  NEXT_PUBLIC_DEFAULT_IMAGE_URL: asUrlString,

  NEXT_PUBLIC_SECTIONS_TO_HIDE: z.string().default(''),
  NEXT_PUBLIC_ADDITIONAL_RTL_LOCALES: z.string().default(''),
  NEXT_PUBLIC_ADOBE_LAUNCH_SCRIPT_URL: asUrlString.or(z.string().length(0)).default(''),

  // Unleash
  NEXT_PUBLIC_UNLEASH_FRONTEND_API_URL: asUrlString,
  NEXT_PUBLIC_UNLEASH_FRONTEND_API_TOKEN: z.string().min(1),
  NEXT_PUBLIC_UNLEASH_APP_NAME: z.string().min(1),

  // Crowdin
  TRANSLATIONS_CACHE_CLEAR_INTERVAL: asIntPositive.default(86400),
})

export type Envs = z.infer<typeof envsSchema>
