import type { UserConfig } from 'next-i18next'

import {
  CROWDIN_LOCALE_MAPPING,
  DEFAULT_CROWDIN_LOCALE,
} from '@knauf-group/ct-shared-nextjs/utils/constants'
import I18NextHttpBackend from 'i18next-http-backend'

import { getEnv } from '@/envs'
import { isClient, isServer } from '@/utils/plain/isClient'

const CROWDIN_APP_DISTRIBUTION_HASH = '9cf0da4c25d45ec41a48bd8xey1'
const CROWDIN_COMMON_DISTRIBUTION_HASH = 'b895fe252fe48fc311fb088xey1'

const DEFAULT_NAMESPACE = 'pcat'

const NAMESPACES_FROM_APP_DISTRIBUTION = [DEFAULT_NAMESPACE]
const NAMESPACES_FROM_COMMON_DISTRIBUTION = [
  'cms',
  'dam-assets',
  'global-search',
  'shared-nextjs',
]

const NAMESPACES = [...NAMESPACES_FROM_APP_DISTRIBUTION, ...NAMESPACES_FROM_COMMON_DISTRIBUTION]

const locales = Object.keys(CROWDIN_LOCALE_MAPPING)

export type Locale = (typeof locales)[number]

const i18nextConfig: UserConfig = {
  i18n: {
    defaultLocale: 'default',
    locales,
  },
  fallbackLng: {
    default: [DEFAULT_CROWDIN_LOCALE],
    'nb-nb': ['nb-NO'],
  },
  ns: NAMESPACES,
  defaultNS: DEFAULT_NAMESPACE,
  use: isClient ? [] : [I18NextHttpBackend],
  backend: {
    loadPath: (languages: string[], namespaces: string[]) => {
      const lng = languages[0]
      const ns = namespaces[0]

      // used when fetching the latest version of the translations from Crowdin CDN
      const currentTimestamp = Date.now()

      const distributionHash = NAMESPACES_FROM_COMMON_DISTRIBUTION.includes(ns)
        ? CROWDIN_COMMON_DISTRIBUTION_HASH
        : CROWDIN_APP_DISTRIBUTION_HASH

      return `https://distributions.crowdin.net/${distributionHash}/content/main/${lng}/${ns}.json?timestamp=${currentTimestamp}`
    },
    reloadInterval: isServer ? getEnv('TRANSLATIONS_CACHE_CLEAR_INTERVAL') * 1000 : undefined,
  },
  serializeConfig: false,
}

export default i18nextConfig
